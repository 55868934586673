import * as d3 from 'd3';

let squareIndex = 0;

const { innerWidth, innerHeight } = window;
const eventType = 'ontouchstart' in document ? 'touchmove' : 'mousemove';
const dispatch = d3.dispatch('remove-square');

dispatch.on('remove-square', canvas => {
	const newSquare = canvas.append('rect');
	const newSquareData = generateSquareData(1);

	setSquareData(canvas, newSquare.node(), newSquareData[0], squareIndex);
});

export const rand = modifier => Math.random() * modifier;

export const createSVG = (id, width, height) => {
	const svg = d3
		.select(`#${id}`)
		.append('svg')
		.attr('width', width)
		.attr('height', height)
		.attr('preserveAspectRatio', 'xMinYMin meet')
		.attr('viewBox', `0 0 ${width} ${height}`);

	return svg;
};

export const drawSquare = (canvas, data) => {
	canvas
		.append('rect')
		.attr('x', data.x)
		.attr('y', data.y)
		.attr('width', 0)
		.attr('height', 0)
		.style('stroke-opacity', 1)
		.style('stroke', data.color)
		.style('fill', 'none')
		.transition()
		.duration(1000)
		.ease(d3.easeQuadIn)
		.attr('width', 100)
		.attr('height', 100)
		.style('stroke-opacity', 0.001)
		.remove();
};

export const setSquareData = (canvas, square, data, index) => {
	d3
		.select(square)
		.attr('class', 'square')
		.attr('x', d => data.x)
		.attr('y', d => data.y)
		.attr('width', 0)
		.attr('height', 0)
		.style('stroke', d => data.color)
		.style('stroke-width', '2')
		.style('stroke-opacity', d => data.op)
		.style('stroke-linecap', 'round')
		.style('fill', 'none')
		.transition()
		.duration(rand(10000))
		.attr('width', d => data.size)
		.attr('height', d => data.size)
		.transition()
		.delay(rand(100000))
		.duration(1000)
		.style('stroke-opacity', 0)
		.on('end', function() {
			dispatch.call('remove-square', null, canvas);

			d3.select(this).remove();
		});
};

export const generateSquareData = count => {
	return d3.range(count).map(() => {
		const x = rand(innerWidth);
		const y = rand(innerHeight);
		const op = rand(1);
		const size = rand(200);
		const color = d3.hsl((squareIndex = (squareIndex + 1) % 360), 20, 0.5);

		return {
			x,
			y,
			op,
			size,
			color
		};
	});
};

export const initCanvas = id => {
	let i = 0;

	const canvas = createSVG(id, innerWidth, innerHeight);

	canvas.on(eventType, function() {
		const offset = d3.mouse(this);
		const color = d3.hsl((i = (i + 1) % 360), 1, 0.5);

		drawSquare(canvas, {
			color,
			x: offset[0],
			y: offset[1]
		});
	});

	return canvas;
};

export const createSquares = canvas => {
	const circles = canvas
		.selectAll('rect')
		.data(generateSquareData(30))
		.enter()
		.append('rect')
		.each(function(d, i) {
			setSquareData(canvas, this, d, i);
		});

	return canvas;
};
